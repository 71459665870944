// dm
import { SIZE_MAX } from '../api';

export default {
  TYPES: {
    pdf: 'application/pdf',
    jpeg: 'image/jpeg',
    png: 'image/png',
  },
  SIZE_MAX,
};
