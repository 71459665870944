// vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// own
import './Tag.scss';
import Tag from './Tag';

export default class TagGroupComponent extends Component {
  static propTypes = {
    list: PropTypes.array,
    styles: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.array,
  };

  static defaultProps = {
    values: null,
  };

  render() {
    const { values, list, styles, diff, ...props } = this.props;
    const realValues = values || null;
    const className = classNames('tag-group', {
      [`tag-${props.className}-group`]: props.className,
    });
    const ZERO = 0;
    return (
      <div className={className}>
        {realValues &&
          !!values.length &&
          list &&
          !!list.length &&
          values.map((elem, i) => {
            const index = diff ? elem - 1 : ZERO;
            return (
              <Tag key={i} {...styles[index]} {...props}>
                {list[index].label}
              </Tag>
            );
          })}
      </div>
    );
  }
}
