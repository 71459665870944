import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import config from '../config';

export function getIdFromCookie() {
  let id;
  const jwt = getAccessToken();
  if (jwt) {
    try {
      const decoded = jwtDecode(jwt);
      id = decoded.id;
    } catch (error) {
      console.error('getIdFromCookie:', error);
    }
  }
  return id;
}

export function deleteUserFromCookie() {
  Cookies.remove('user');
}

export function getAccessToken() {
  return Cookies.get('accessToken');
}

export function getAccessTokenExp() {
  return Cookies.get('accessTokenExp');
}

export function getRefreshToken() {
  return Cookies.get('refreshToken');
}

export function isAccessTokenExpired() {
  const accessTokenExpDate = Number(getAccessTokenExp());
  const nowTime = Math.floor(new Date().getTime() / 1000);

  return accessTokenExpDate <= nowTime;
}

export function setAuthData({ accessToken, refreshToken }) {
  const exp = jwtDecode(accessToken).exp;
  Cookies.set('accessToken', accessToken, { expires: 365 });
  Cookies.set('refreshToken', refreshToken, { expires: 365 });
  Cookies.set('accessTokenExp', exp, { expires: 365 });
}

export function resetAuthData() {
  Cookies.remove('accessToken');
  Cookies.remove('refreshToken');
  Cookies.remove('accessTokenExp');
}

export function refreshTokens() {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `https://${config.apiHost}/${config.apiVersion}/auth/refresh-token`,
        { refresh_token: getRefreshToken() },
      )
      .then(({ data }) => {
        setAuthData({
          accessToken: data.data.access_token,
          refreshToken: data.data.refresh_token,
        });
        return resolve(data.data);
      })
      .catch(error => {
        if (error.response.data.code === 422) {
          console.error(error.response.data.message);
        }
        return reject(error);
      });
  });
}
