// vendor
import { includes } from 'lodash';
// dm
import { createConstants } from '../../utils/core';

/* action types */
export const types = createConstants('MARK_FIRST_EMPTY_REQUIRED');

export const markFirstEmptyRequired = (values, notRequiredNames) => {
  const emptyFields = Object.keys(values).filter(
    key =>
      (!values[key] || (Array.isArray(values[key]) && !values[key].length)) &&
      !includes(notRequiredNames, key),
  );
  const setFiendsValue = val => {
    const fields = {};
    emptyFields.map(key => {
      fields[key] = val;
      return key;
    });
    return fields;
  };
  const asyncErrorsRequired = setFiendsValue(true);
  const setRequiredTouched = setFiendsValue({ touched: true });
  return {
    type: types.MARK_FIRST_EMPTY_REQUIRED,
    payload: asyncErrorsRequired
      ? {
          asyncErrors: { ...asyncErrorsRequired },
          fields: { ...setRequiredTouched },
        }
      : {},
  };
  // const firstEmptyRequired = findKey(values, (value, name) => (
  //   !value && !includes(notRequiredNames, name)
  // ));
  // return {
  //   type: types.MARK_FIRST_EMPTY_REQUIRED,
  //   payload: firstEmptyRequired ? {
  //     asyncErrors: { [firstEmptyRequired]: true },
  //     fields: { [firstEmptyRequired]: { touched: true } },
  //   } : {},
  // };
};
