// vendor
import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// own
import './Accordion.scss';

export default class AccordionComponent extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { className, children } = this.props;
    const fullClassName = classNames(className, 'accordion', {
      'accordion--open': this.state.isOpen,
    });
    const childrenWithProps = Children.map(children, child =>
      cloneElement(child, {
        toggle: this.toggle,
        isOpen: this.state.isOpen,
      }),
    );
    return <div className={fullClassName}>{childrenWithProps}</div>;
  }
}
