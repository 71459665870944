/** Configuration */
const config = {
  reduxLoggerOn: process.env.REACT_APP_REDUX_LOGGER_ON,
  publicAppUrl: process.env.REACT_APP_PUBLIC_APP_URL,
  tokenDomain: process.env.REACT_APP_TOKEN_DOMAIN,
  apiHost: process.env.REACT_APP_API_HOST,
  apiVersion: process.env.REACT_APP_API_VERSION,
  googleMapsId: process.env.REACT_APP_GOOGLE_MAPS_ID,
};

export default config;
