export { default as ADMIN_ROLES } from './adminRoles';

export const HIGHLIGHTS_MIN = 3;
export const HIGHLIGHTS_MAX = 5;

export const TEAM_MAX = 5;

export const SUMMARY_MAX = 180;

export const FUND_STRATEGIES_MAX = 5;

export const INDUSTRY_FOCUSES_MAX = 5;
export const CREDIT_FOCUSES_MAX = 5;

export const ANNUAL_RETURNS_MIN = 2;
export const ANNUAL_RETURNS_MAX = 5;

export const METRICS_MIN = 3;
export const METRICS_MAX = 5;

export const PREVIOUS_MAX = 5;

export const REP_INVESTMENTS_MAX = 5;
