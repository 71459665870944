// vendor
import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
// dm
import { userTypes, fundTypes } from '../actions';
import {
  checkGuest,
  checkInvestor,
  checkAuthorized,
  checkAssetManager,
} from '../../utils/access';
import { getIsKycCompleted } from '../../utils/kyc';
import { createReducer } from '../../utils/core';
import { GUEST } from '../../constants/user/roles';

/* initial state */
const initialState = fromJS({
  // messages
  isActivated: false,
  isNotActivated: false,
  isNotConfirmed: false,
  notification: false,
  isMissingOnApiSide: false,
  unsubscribe: false,
  // registration fields
  returnUrl: null,
  email: null,
  firstName: null,
  lastName: null,
  password: null,
  accreditation: null,
  isAcceptTerms: null,

  id: undefined,
  access: GUEST,
  isAuthorized: false,

  suitabilityStatus: '',
  kycStatus: null,
  isKycCompleted: null,
  isProfileCompleted: null,
  proofOfIdentityMedia: {},

  dob: null,
  avatar: {},
  fullName: null,
  unreadFeedsAmount: null,
  unreadMessagesAmount: null,
  accountAddress: null,
  accountCity: null,
  accountCountryId: null,
  accountEntityTypeId: null,
  accountPhone: null,
  accountRegistration: null,
  accountStateId: null,
  accountTypeId: null,
  accountZip: null,
  additionalNotes: null,
  alternativeAssets: null,
  associatedAccount: null,
  associatedPerson: null,
  averageInvestment: null,
  avgIncomePastTwoYears: null,
  domesticAccount: null,
  domicile: null,
  educationHighestLevelId: null,
  employerAddress: null,
  employerCity: null,
  employerCountryId: null,
  employerName: null,
  employerStateId: null,
  employerZip: null,
  employmentStatusId: null,
  financialAdviser: null,
  firmAum: null,
  firmName: null,
  hedgeFundStrategies: null,
  householdApproximateNetWorth: null,
  householdAvgIncomePastTwoYears: null,
  industryFocuses: null,
  investTo: null,
  investmentExperience: null,
  investmentObjectiveId: null,
  investorType: null,
  linkedinUrl: null,
  occupation: null,
  phoneNumber: null,
  preferredAssetTypes: null,
  primAddress: null,
  primCity: null,
  primCountryId: null,
  primStateId: null,
  primZip: null,
  privateOfferingExperience: null,
  proofOfIdentityMediaId: null,
  qualifications: null,
  riskTolerance: null,
  taxId: null,
  taxType: null,
  updatedAt: null,
  ventureCapitalStageFocuses: null,
  portfolioTimeHorizon: null,
});

/* selectors */
export const currentUserSelector = state => state.get('user');
export const currentUserIdSelector = createSelector(
  currentUserSelector,
  user => user.get('id'),
);
export const currentUserEmailSelector = createSelector(
  currentUserSelector,
  user => user.get('email'),
);
export const currentUserAccessSelector = createSelector(
  currentUserSelector,
  user => user.get('access'),
);
export const isGuestSelector = createSelector(
  currentUserAccessSelector,
  access => checkGuest({ access }),
);
export const isAuthorizedSelector = createSelector(
  currentUserAccessSelector,
  access => checkAuthorized({ access }),
);
export const isAssetManagerSelector = createSelector(
  currentUserAccessSelector,
  access => checkAssetManager({ access }),
);
export const isInvestorSelector = createSelector(
  currentUserAccessSelector,
  access => checkInvestor({ access }),
);

/* reducer */
export default createReducer(initialState, {
  [userTypes.SIGNUP_USER_SUCCESS]: (state, { response }) =>
    state.merge({ ...response.data }),
  [userTypes.AUTHORIZE_USER_SUCCESS]: (state, user) => state.merge(user),
  [userTypes.SIGNIN_USER_SUCCESS]: (state, { response: { data } }) => {
    const { accessToken, refreshToken, ...user } = data;
    return state.merge({ ...user });
  },
  [userTypes.LOGOUT_USER]: state => state.merge(initialState),
  [userTypes.READ_USER_SUCCESS]: (state, { response }) =>
    state.merge({
      ...response.data,
      isKycCompleted: getIsKycCompleted(response.data.kycStatus),
    }),
  [userTypes.READ_PROFILE_SUCCESS]: (state, { response }) =>
    state.merge({ ...response.data }),
  [userTypes.CREATE_PROFILE_SUCCESS]: (state, { response: { data } }) =>
    state.merge({
      ...data,
      fullName: `${data.firstName} ${data.lastName}`,
      isProfileCompleted: true,
    }),
  [userTypes.CREATE_AVATAR_SUCCESS]: (state, { response }) =>
    state.merge({ avatar: { url: response.data.avatarUrl } }),
  [userTypes.READ_UNREAD_FEED_AMOUNT_SUCCESS]: (state, { response }) =>
    state.merge({ unreadFeedsAmount: response.data.unreadFeedAmount }),
  [fundTypes.READ_UNREAD_MESSAGES_AMOUNT_SUCCESS]: (state, { response }) =>
    state.merge({ unreadMessagesAmount: response.data.unreadAmount }),
  [userTypes.SET_USER_FROM_COOKIE]: (state, user) => state.merge(user),
  [userTypes.NOTIFICATION_TOGGLE]: (state, data) => state.merge(data),
  [userTypes.MERGE_USER]: (state, user) => state.merge(user),
  [userTypes.USER_CONFIRM_UNSUBSCRIBE_SUCCESS]: (state, user) =>
    state.merge(user),
  [userTypes.ACTIVATE_ACCOUNT_SUCCESS]: state =>
    state.merge({ isActivated: true }),
});
