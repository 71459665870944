// vendor
import { formValueSelector, getFormValues } from 'redux-form/immutable';
import { fromJS } from 'immutable';
import { omitBy, isNil } from 'lodash';

// dm
import { types } from '../../actions/investments';
import { investmentTypes } from '../../actions';
import { createReducer } from '../../../utils/core';

/* form name */
export const form = 'investment';

/* initial state */
const initialState = fromJS({
  values: {
    defaultDocument: {},
    investorDocument: {},
    amDocument: {},
    details: {},
    investmentStatus: {
      id: 0,
    },
    transactionType: {
      id: 0,
    },
    transaction: 0,
  },
});

/* reducer */
export default createReducer(initialState, {
  [types.READ_INVESTMENT_FAILURE]: state => state,
  [types.READ_INVESTMENT_SUCCESS]: (state, { response }) =>
    state.mergeDeepIn('values'.p(), fromJS(response.data)),
  [types.UPDATE_INVESTMENT_FUND]: (state, payload) =>
    state.mergeDeepIn('values'.p(), fromJS(payload)),
  [types.READ_INVESTMENT_DETAILS_SUCCESS]: (state, { response }) =>
    state.mergeDeepIn('values.details'.p(), fromJS(response.data)),
  [investmentTypes.CREATE_INVESTMENTS_SUCCESS]: (state, { response }) =>
    state.mergeDeep({ values: omitBy(response.data, isNil) }),
  [investmentTypes.CREATE_INVESTMENT_MEDIA_REQUEST]: (state, { progress }) =>
    state.setIn('values.progress'.p(), progress),
  [investmentTypes.CREATE_INVESTMENT_MEDIA_SUCCESS]: (
    state,
    { response, extraData: { fieldName } },
  ) => state.setIn(`values.${fieldName}`.p(), fromJS(response.data)),
});

/* selectors */
export const selector = formValueSelector(form);
export const valuesSelector = getFormValues(form);
