import { SIZE_MAX } from '../api';

export default {
  TYPES: {
    pdf: 'application/pdf',
    zip:
      'application/zip, application/octet-stream, application/x-zip-compressed',
  },
  SIZE_MAX,
};
