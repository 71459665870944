// vendor
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import { isEmpty, isBoolean } from 'lodash';

// dm
import Tooltip from '../../molecules/Tooltip';
import { DESKTOP, TABLET, MOBILE } from '../../../constants/breakpoints';
import { toJS } from '../../../utils/core';

// own
import './Checkable.scss';

export default class CheckableComponent extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf('checkbox radio'.w()),
    meta: PropTypes.object,
    input: PropTypes.object,
    value: PropTypes.any,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    optionRender: PropTypes.func,
    prevent: PropTypes.bool,
    translate: PropTypes.bool,
    tooltip: PropTypes.node,
    onClick: PropTypes.func,
    intlValues: PropTypes.object,
  };

  static defaultProps = {
    type: 'checkbox',
    translate: true,
    intlValues: {},
  };

  handleChange = event => {
    event.stopPropagation();
    const { prevent, onChange, input } = this.props;
    if (prevent) {
      return event.preventDefault();
    }
    if (onChange) {
      return onChange(event, input.onChange);
    }
    return input.onChange(event);
  };

  renderText({ OptionComponent, translate, label }) {
    let text;
    if (OptionComponent) {
      text = <OptionComponent {...this.props} />;
    } else if (translate) {
      text = <FormattedMessage id={label} values={this.props.intlValues} />;
    } else {
      text = <span>{label}</span>;
    }
    return text;
  }

  render() {
    const {
      meta,
      input,
      className,
      label,
      OptionComponent,
      translate,
      tooltip,
      ...props
    } = this.props;
    const { value = toJS(input.value) } = props;
    const {
      checked = isBoolean(value) ? value : !isEmpty(value),
      type,
      disabled,
      onClick,
    } = props;
    const fullClassName = classNames(
      className,
      `checkable checkable--${type}`,
      {
        'checkable--checked': checked,
        'checkable--disabled': disabled,
        'checkable--error': meta.error && meta.touched,
      },
    );
    const id = `checkable-${input.name}-${value}`;
    return (
      <div className={fullClassName}>
        <label htmlFor={id}>
          <input
            {...input}
            {...{ value, id, checked, type, disabled, onClick }}
            onChange={this.handleChange}
          />
          {label &&
            this.renderText({
              OptionComponent,
              translate,
              label,
            })}
        </label>
        {tooltip && (
          <MediaQuery minWidth={DESKTOP.left}>
            <Tooltip>{tooltip}</Tooltip>
          </MediaQuery>
        )}
        {tooltip && (
          <MediaQuery minWidth={TABLET.left} maxWidth={TABLET.right}>
            <Tooltip position="top-left">{tooltip}</Tooltip>
          </MediaQuery>
        )}
        {tooltip && (
          <MediaQuery maxWidth={MOBILE.right}>
            <Tooltip responsive>{tooltip}</Tooltip>
          </MediaQuery>
        )}
      </div>
    );
  }
}
